import React, {Dispatch, SetStateAction} from 'react';
import galleryJSON from "../../static/data/gallery.json";
import {EPortfolio} from "../../pages/Home";
import {IGalleryItem} from "../../../interfaces";

const galleryItems = galleryJSON as IGalleryItem [];

interface IProps {
	setPortfolio: Dispatch<SetStateAction<EPortfolio>>;
}

function renderMatrixCode(): JSX.Element[] {
	const render = [];
	for (let i = 0; i < 20; i++) {
		render.push(<div key={`matrix-${i}`} className="matrix__code"/>);
	}
	return render;
}

function renderGalleryGrid(): JSX.Element[] {
	const render: JSX.Element[] = [];
	let thumbnailMerge: { slug: string; filename: string; }[] = [];

	galleryItems.forEach((element: IGalleryItem) => {

		// skip disable items
		// if (!element.status) return;

		const galleryItemThumbnails = element.steps.map((step) => {
			return {
				slug: element.slug,
				filename: step.thumbnail,
			}
		});
		thumbnailMerge = thumbnailMerge.concat(galleryItemThumbnails);
	});

	for (let i = 0; i <= 400; i++) {
		const randomThumbnail = thumbnailMerge[Math.floor(Math.random() * thumbnailMerge.length)];
		render.push(
			<div
				key={`gallery-grid-${i}`}
				className="animated-background__grid__item"
				style={{backgroundImage: `url("https://files.magicmediamuse.com/file/magicmediamuse/gallery/${randomThumbnail.slug}/${randomThumbnail.filename}")`}}
			/>
		);
	}
	return render;
}

const Portfolio: React.FC<IProps> = (props: IProps) => {
	const {setPortfolio} = props;

	return (
		<div className="page-section portfolio">
			<div className="portfolio__parallax">
				<div
					className="portfolio__parallax__layer portfolio__parallax__layer--programming"
					onClick={() => setPortfolio(EPortfolio.PROGRAMMING)}
				>
					<div className="matrix">
						{renderMatrixCode()}
					</div>
					<div className="portfolio__parallax__layer__title h2">
						Programming
					</div>
				</div>

				<div
					className="portfolio__parallax__layer portfolio__parallax__layer--graphics"
					onClick={() => setPortfolio(EPortfolio.GRAPHICS)}
				>
					<div className="animated-background">
						<div className="animated-background__grid">
							{renderGalleryGrid()}
						</div>
					</div>
					<div className="portfolio__parallax__layer__title h2">
						Graphic Design
					</div>
				</div>
			</div>
		</div>
	);
};

export default Portfolio;
