import React, {useEffect, useState} from 'react';
import logo from '../../static/images/logo.svg';
import skillsJSON from "../../static/data/skills.json";
import {ISkillsItem} from "../../../interfaces";

const skillsItems = skillsJSON as ISkillsItem[];

const Skills: React.FC = () => {
	const [topPos, setTopPos] = useState<number>(0);
	const [listHover, setListHover] = useState<boolean>(false);
	const [skillIndex, setSkillIndex] = useState<number | undefined>(undefined);
	const [skillIndexPrevious, setSkillIndexPrevious] = useState<number | undefined>(undefined);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (!listHover) {
				setSkillIndexPrevious(skillIndex);
				setSkillIndex(undefined);
			}
		}, 50);

		return function cleanup() {
			clearTimeout(timeoutId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listHover]);

	function handleListMouseMove(event: React.MouseEvent<HTMLDivElement>) {
		const maxOffset = 100;
		// const hoverY = event.nativeEvent.offsetY; // Mouse position relative to current hovered element (possibly children)
		const element = event.currentTarget.getBoundingClientRect();
		const hoverY = event.pageY - window.pageYOffset - element.top; // Mouse position relative to element
		const percentageTop = hoverY / element.height;

		setTopPos(maxOffset * percentageTop * -1);
	}

	function handleListItemMouseEnter(index: number) {
		setSkillIndexPrevious(skillIndex);
		setListHover(true);
		setSkillIndex(index);
	}

	function handleListItemMouseLeave() {
		setListHover(false);
	}

	function renderList(): JSX.Element[] {
		return skillsItems.map((element: ISkillsItem, index: number) => {
			return (
				<div
					key={`skill-${index}`}
					className="skills__list__inner__item"
					onMouseEnter={() => handleListItemMouseEnter(index)}
					onMouseLeave={handleListItemMouseLeave}
				>
					<div className="skills__list__inner__item__title">{element.title}</div>
				</div>
			)
		});
	}

	function renderIcons(): JSX.Element[] {
		const render: JSX.Element[] = skillsItems.map((element: ISkillsItem, index: number) => {
			return (
				<div
					key={`skill-icon-${index}`}
					className={`skills__icons__item ${(skillIndex === index) && "mask"} ${(skillIndexPrevious === index) && "backdrop"}`}
				>
					<div className={`skills__icons__item__icon ${element.icon}`}/>
				</div>
			);
		});

		render.push(
			<div
				key="default"
				className={`skills__icons__item ${(skillIndex === undefined) && "mask"} ${(skillIndexPrevious === undefined) && "backdrop"}`}
			>
				<div className="skills__icons__item__icon">
					<img src={logo} alt="magicmediamuse"/>
				</div>
			</div>
		);

		return render;
	}

	// function renderIcon(): JSX.Element {
	// 	if (skillIndex === undefined) {
	// 		return (
	// 			<div key="default" className="skills__icons__item mask">
	// 				<div className="skills__icons__item__icon">
	// 					<img src={logo} alt="magicmediamuse"/>
	// 				</div>
	// 			</div>
	// 		);
	// 	} else {
	// 		const icon = skillsItems[skillIndex].icon;
	// 		return (
	// 			<div key={icon} className="skills__icons__item mask">
	// 				<div className={`skills__icons__item__icon ${icon}`}/>
	// 			</div>
	// 		);
	// 	}
	// }

	return (
		<div id="skills" className="page-section skills">
			<h2 className="skills__title">My Skills</h2>
			<div className="skills__list" onMouseMove={handleListMouseMove}>
				<div className="skills__list__inner" style={{top: `${topPos}px`}}>
					{renderList()}
				</div>
			</div>

			<div className="skills__icons">
				{renderIcons()}
			</div>
		</div>
	);
};

export default Skills;
