import React, {Dispatch, SetStateAction, useState} from 'react';
import {EPortfolio} from "../../pages/Home";
import galleryJSON from "../../static/data/gallery.json";
import {IGalleryItem} from "../../../interfaces";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const galleryItems = galleryJSON as IGalleryItem [];
const gallerySorted: IGalleryItem[] = galleryItems.sort(compareWeight).filter((galleryItem: IGalleryItem) => galleryItem.status);

interface IProps {
	setPortfolio: Dispatch<SetStateAction<EPortfolio>>;
}

function compareWeight(a: IGalleryItem, b: IGalleryItem) {
	if (a.weight < b.weight) {
		return -1;
	}
	if (a.weight > b.weight) {
		return 1;
	}
	return 0;
}


const GalleryGrid: React.FC<IProps> = (props: IProps) => {
	const {setPortfolio} = props;
	const [lightboxIndex, setLightboxIndex] = useState<number | false>(false);

	function renderGalleryGrid(): JSX.Element[] {
		const render: JSX.Element[] = [];

		// Wrap items in 3 element chunks
		const chunks: IGalleryItem[][] = [];
		const tempArr = [...gallerySorted];
		while (tempArr.length > 0) {
			chunks.push(tempArr.splice(0, 3));
		}

		chunks.forEach((chunk: IGalleryItem[], chunkIndex: number) => {
			const renderChunk: JSX.Element[] = [];
			const twoPosition: string = ((chunkIndex + 1) % 2 === 0) ? "even" : "odd";
			let threePosition: string;
			if ((chunkIndex) % 3 === 0) {
				threePosition = 'first';
			} else if ((chunkIndex + 2) % 3 === 0) {
				threePosition = 'second';
			} else {
				threePosition = 'third';
			}

			chunk.forEach((galleryItem: IGalleryItem, chunkItemIndex: number) => {
				const slug = galleryItem.slug;
				const imageIndex = gallerySorted.findIndex((element: IGalleryItem) => element.slug === slug);

				renderChunk.push(
					<div
						key={`image-${slug}`}
						className={`gallery-grid__chunk__item card-wrapper animated fadeInUp item-${chunkItemIndex + 1} image-index-${imageIndex}`}
					>
						<span
							className="galpop card cursor-pointer"
							onClick={() => setLightboxIndex(imageIndex)}
						>
							<div className="face front">
								<img src={`https://files.magicmediamuse.com/file/magicmediamuse/gallery/${slug}/finishedTb.jpg`}
								     alt={galleryItem.title} className="obj-cover"/>
							</div>
							<div className="face back">
								<div className="gallery-item-content">
									{galleryItem.title}
								</div>
							</div>
					</span>
					</div>
				);
			});

			render.push(
				<div key={`chunk-${chunkIndex}`}
				     className={`gallery-grid__chunk col-${chunkIndex + 1} col-2-${twoPosition} col-3-${threePosition}`}
				>
					{renderChunk}
				</div>
			);
		});

		return render;
	}

	function renderLightbox(): JSX.Element {
		if (lightboxIndex === false) return (<React.Fragment/>);

		const galleryItem: IGalleryItem = gallerySorted[lightboxIndex];
		const imageTitle = galleryItem.title;
		const imageCaption = galleryItem.body;
		const prevIndex = (lightboxIndex + gallerySorted.length - 1) % gallerySorted.length;
		const nextIndex = (lightboxIndex + 1) % gallerySorted.length;
		const mainSrc = `https://files.magicmediamuse.com/file/magicmediamuse/gallery/${galleryItem.slug}/finished.jpg`;
		const prevSrc = `https://files.magicmediamuse.com/file/magicmediamuse/gallery/${gallerySorted[prevIndex].slug}/finished.jpg`;
		const nextSrc = `https://files.magicmediamuse.com/file/magicmediamuse/gallery/${gallerySorted[nextIndex].slug}/finished.jpg`;

		return (
			<Lightbox
				mainSrc={mainSrc}
				prevSrc={prevSrc}
				nextSrc={nextSrc}
				imageTitle={imageTitle}
				imageCaption={imageCaption}
				onCloseRequest={() => setLightboxIndex(false)}
				onMovePrevRequest={() => setLightboxIndex(prevIndex)}
				onMoveNextRequest={() => setLightboxIndex(nextIndex)}
			/>
		);
	}

	return (
		<React.Fragment>
			{renderLightbox()}

			<div className="gallery-grid">
				<button
					onClick={() => setPortfolio(EPortfolio.PORTFOLIO)}
					className="gallery-grid__back btn-theme animated fadeIn "
				>
					<span className="span-default">Go Back</span>
					<span className="span-hover">Programming / Graphic Design</span>
				</button>
				<div className="gallery-grid__container">
					<div className="row">
						{renderGalleryGrid()}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default GalleryGrid;
