import React, {useState} from 'react';
import Profile from "../components/partials/Profile";
import Portfolio from "../components/partials/Portfolio";
import ProgrammingGrid from "../components/partials/ProgrammingGrid";
import GalleryGrid from "../components/partials/GalleryGrid";
import Skills from '../components/partials/Skills';

export enum EPortfolio {
	"PORTFOLIO",
	"PROGRAMMING",
	"GRAPHICS"
}

const Home: React.FC = () => {
	const [portfolio, setPortfolio] = useState<EPortfolio>(EPortfolio.PORTFOLIO);

	return (
		<React.Fragment>
			<Profile/>
			{(portfolio === EPortfolio.PORTFOLIO) && <Portfolio setPortfolio={setPortfolio}/>}
			{(portfolio === EPortfolio.PROGRAMMING) && <ProgrammingGrid setPortfolio={setPortfolio}/>}
			{(portfolio === EPortfolio.GRAPHICS) && <GalleryGrid setPortfolio={setPortfolio}/>}
			<Skills/>
		</React.Fragment>
	);
};

export default Home;
