import React from 'react';
import photo from '../../static/images/vancouver-web-developer-richard-hung.png';
import logo from '../../static/images/logo-text.svg';

const Profile: React.FC = () => {

	return (
		<div id="profile" className="page-section profile">
			<div className="profile__logo animated fadeIn">
				<img src={logo} alt="magicmediamuse"/>
			</div>

			<div className="profile__image">
				<img src={photo} alt="Vancouver web developer Richard Hung"/>
			</div>

			<div className="profile__body animated fadeIn">
				<p>I'm Richard, a full stack engineer in Vancouver, BC with over ten years experience in a wide variety
					of skills. My journey as a web developer started in high school after selecting two website
					courses in the last two years. Soon after graduating from the New Media and Web Development program
					at BCIT, I landed my first job at Burst! Creative Group Inc and got the chance to build websites for
					companies such as Landyachtz, White Spot Restaurants, and Asia Standard Americas. I'm currently
					working as a full stack engineer at Prizm Media and I'm a MERN developer coding in TypeScript
					building applications in Docker containers. My projects include health applications for pharmacies,
					durable medical equipment suppliers, patients, and doctors.</p>

				<p>Over the course of my career, I've come to the belief that it's easier to code my own functionality
					than it is to heavily modify a pre-made solution. "Hacking" another developer's work is like
					attempting to fit a square peg in a round hole. Even if you force it to work now, it will come back
					to wreak havoc on the project in the future. Coding my own functionality has taught me to logically
					think through all the steps in my head or write them down on paper before putting my hands to the
					keyboard.</p>

				<p>Contact: <a href="mailto:richardhung111@gmail.com">richardhung111@gmail.com</a></p>
			</div>

			<div className="profile__social animated fadeIn">
				<a href="https://www.linkedin.com/in/rhung1" rel="noopener noreferrer" target="_blank" title="LinkedIn">
					<i className="fab fa-linkedin" aria-hidden="true"/>
				</a>
				<a href="https://angel.co/richard-hung-2" rel="noopener noreferrer" target="_blank" title="AngelList">
					<i className="fab fa-angellist" aria-hidden="true"/>
				</a>
				<a href="https://github.com/Richard1320" rel="noopener noreferrer" target="_blank" title="GitHub">
					<i className="fab fa-github" aria-hidden="true"/>
				</a>
				<a href="https://codepen.io/richardhung" rel="noopener noreferrer" target="_blank" title="CodePen">
					<i className="fab fa-codepen" aria-hidden="true"/>
				</a>
			</div>


			<div className="curtain">
				<div className="curtain__panel curtain__panel--left">
					<div className="curtain__panel__triangle curtain__panel--left__triangle"/>
				</div>
				<div className="curtain__panel curtain__panel--right">
					<div className="curtain__panel__triangle curtain__panel--right__triangle"/>
				</div>
			</div>

		</div>
	);
};

export default Profile;
