import React from 'react';

import './scss/app.scss';
import RouteList from "./RouteList";

const App: React.FC = () => {
	return (
		<div className="component--app">
			<main>
				<RouteList/>
			</main>
		</div>
	);
};

export default App;
