import React, {Dispatch, SetStateAction} from 'react';
import {EPortfolio} from "../../pages/Home";
import programmingJSON from "../../static/data/programming.json";
import {IProgrammingItem} from "../../../interfaces";

const programmingItems = programmingJSON as IProgrammingItem[];
const programmingSorted: IProgrammingItem[] = programmingItems.sort(compareWeight).filter((programmingItem: IProgrammingItem) => programmingItem.status);

interface IProps {
	setPortfolio: Dispatch<SetStateAction<EPortfolio>>;
}

function compareWeight(a: IProgrammingItem, b: IProgrammingItem) {
	if (a.weight < b.weight) {
		return -1;
	}
	if (a.weight > b.weight) {
		return 1;
	}
	return 0;
}


function renderProgrammingGrid(): JSX.Element[] {
	const render: JSX.Element[] = [];

	programmingSorted.forEach((programmingItem: IProgrammingItem, index: number) => {

		render.push(
			<div key={`programming-${index}`} className="programming-grid__grid__item animated fadeIn tag-active">
				<a href={programmingItem.url} target="_blank" rel="noopener noreferrer">
					<figure className="programming-grid__grid__item__figure">
						<img
							src={`https://files.magicmediamuse.com/file/magicmediamuse/programming/${programmingItem.slug}/${programmingItem.logo}`}
							alt={programmingItem.title}
							className="programming-grid__grid__item__figure__image"
						/>
						<figcaption className="programming-grid__grid__item__figure__caption">
							<div className="content">
								<h3>{programmingItem.title}</h3>
								<div className="programming-grid__grid__item__figure__caption__features">
									<p>
										{programmingItem.tags.join(", ")}
									</p>
								</div>
							</div>
						</figcaption>
					</figure>
				</a>
			</div>
		);
	});

	return render;
}

const ProgrammingGrid: React.FC<IProps> = (props: IProps) => {
	const {setPortfolio} = props;

	return (
		<div className="programming-grid">
			<button
				onClick={() => setPortfolio(EPortfolio.PORTFOLIO)}
				className="programming-grid__back btn-theme animated fadeIn "
			>
				<span className="span-default">Go Back</span>
				<span className="span-hover">Programming / Graphic Design</span>
			</button>
			<div className="programming-grid__grid">

				{renderProgrammingGrid()}

			</div>
		</div>
	);
};

export default ProgrammingGrid;
